import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useToast,
  Box,
  Text,
  Checkbox,
} from '@chakra-ui/react'
import { get, replace } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { StateStore } from '../Store'
import Api from '../../lib/api'
import { DropdownIndicator } from '../InputsV2/Select'
import { baseStyles } from '../Onboarding/MarketplaceConfigurationModal'
import { Select } from 'chakra-react-select'

type Props = {}

type Option = {
  label: string
  value: string
}

interface Response {
  full_name: string
  success: boolean
  errors?: {
    message: string
    status_code: string
  }
}

function parseResponses(responses: Response[]) {
  let successCount = 0
  const errors: Array<{
    full_name: string
    message: string
    status_code: string
  }> = []

  responses.forEach(response => {
    if (response.success) {
      successCount++
    } else if (response.errors) {
      errors.push({
        full_name: response.full_name,
        message: response.errors.message,
        status_code: response.errors.status_code,
      })
    }
  })

  return {
    successCount,
    errors,
  }
}

const ReplacePicklistDialogue = (props: Props) => {
  const { state, dispatch } = useContext(StateStore)
  const isOpen = (get(state, 'modal.key', '') as string) === 'replacePicklist'
  const { selectedField, selectedType, references } = get(state, 'modal.data', {
    selectedField: { field_name: '', object_name: '' },
    selectedType: '',
    references: {},
  })

  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const [replaceValues, setReplaceValues] = useState<boolean>(false)

  const toast = useToast()
  const user = get(state, 'user')
  const [errors, setErrors] = useState([])

  const baseRef = get(state, 'dialogue.rootRef.ref')

  const [options, setOptions] = useState([])

  const [selectedOption, setSelectedOption] = useState<Option | null>(null)

  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    Api.salesforce.objects.objectName.customFields
      .getCustomField({
        customFieldName: selectedField.field_name,
        objectName: selectedField.object_name,
      })
      .then(field => {
        setOptions(
          field.picklist_values?.map((option: any) => ({
            value: option.value,
            label: option.value,
          })),
        )
      })
  }, [selectedField, selectedType])

  const onClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      portalProps={{
        containerRef: baseRef,
      }}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      size='lg'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize='20px' fontWeight={600}>
            Select a Replacement Picklist Value
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            mt={10}
            background='rgb(248, 248, 248)'
            borderRadius='10px'
            pb={3}
            pt={4}
            px={5}
            mb={menuOpen ? '310px' : '50px'}
            h='full'
          >
            <Box>
              <Text pb={2}>
                Please select a new picklist value to replace {selectedType}
              </Text>
              <Select
                options={options}
                chakraStyles={baseStyles({
                  color: 'black',
                  backgroundColor: 'white',
                  height: '40px',
                  fontSize: '13px',
                })}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: DropdownIndicator,
                }}
                onChange={e => {
                  setSelectedOption(e)
                }}
                onMenuOpen={() => {
                  setMenuOpen(true)
                }}
                onMenuClose={() => {
                  setMenuOpen(false)
                }}
                placeholder='Select replacement value...'
              />
            </Box>
          </Box>
          <Box
            mt={10}
            background='rgb(248, 248, 248)'
            borderRadius='10px'
            pb={3}
            pt={4}
            px={5}
            mb={'50px'}
            h='full'
          >
            <Flex alignItems='center' justifyContent='space-between'>
              <Text>Would you like to migrate records to the new value?</Text>
              <Checkbox
                colorScheme='purple'
                background='white'
                onChange={e => {
                  if (e.target.checked) {
                    setReplaceValues(true)
                  } else {
                    setReplaceValues(false)
                  }
                }}
              />
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            size={'sm'}
            isDisabled={!selectedOption || isUpdating}
            onClick={() => {
              setIsUpdating(true)
              // Make API Call Here
              Api.salesforce.customFields
                .updateReferences({
                  newField: selectedOption?.value ?? '',
                  oldField: selectedType,
                  objectName: selectedField.object_name,
                  references: JSON.stringify(references),
                  replaceValues: replaceValues,
                  fieldName: selectedField.field_name,
                  isPicklistReplace: true,
                })
                .then(data => {
                  const { successCount, errors } = parseResponses(
                    data.responses,
                  )

                  if (successCount > 0) {
                    toast({
                      description: `Successfuly updated ${successCount} references`,
                      status: 'success',
                      position: 'top',
                    })
                  }

                  if (errors.length > 0) {
                    errors.forEach(error => {
                      toast({
                        description: `Error updating references for ${error.full_name}: ${error.message}`,
                        status: 'error',
                        position: 'top',
                        duration: 5000,
                      })
                    })
                  }

                  if (!replaceValues) {
                    setIsUpdating(false)
                    onClose()
                  }
                })

              setTimeout(() => {
                toast({
                  description: `Successfully updated picklist values from ${selectedType} to ${
                    selectedOption?.value
                  }.
                  ${
                    replaceValues
                      ? 'It may take a few minutes for records to be updated with the new value.'
                      : ''
                  }`,
                  status: 'success',
                })

                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    key: '',
                    data: {},
                  },
                })
              }, 2500)
            }}
            variant='primaryCTA'
          >
            {isUpdating ? 'Updating...' : 'Confirm'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReplacePicklistDialogue
