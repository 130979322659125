import React, { useState } from 'react'
import {
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react'
import styled from 'styled-components'
import { Search } from 'react-feather'
import { truncateString } from './Alerts/utils'

const Option = styled.div<any>`
  height: 40px;
  min-height: 40px;
  font-size: 13px;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 15px;
  color: rgb(77, 77, 77);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${props => (props.isSelected ? '#f8f8f8' : '')};

  &:hover {
    background-color: #f8f8f8;
  }
`

export const Selection = styled.div<any>`
  background-color: white;
  border: 1px solid rgb(226, 232, 240);
  max-width: ${props => props.maxWidth};
  min-height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: ${props => (props.showPlaceholder ? 'flex-start' : 'left')};
  flex-wrap: wrap;
  padding: 5px;
  &:hover {
    border-color: black;
    cursor: pointer;
  }
  ::placeholder {
    font-size: 15px;
  }
`

export const Pill = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  margin-left: 5px;
  background-color: ${props => props.color || '#e0e0e0'};
  border-radius: 6px;
  font-size: 12px;
  &:first-child {
    margin-left: 0;
  }
`

export const PillsWrapper = styled.td`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
`

function fieldLabelText(fieldName: string): string {
  if (typeof fieldName !== 'string' || !fieldName) {
    return ''
  }

  switch (fieldName) {
    case 'Owner ID':
      return 'Owner'
    case 'Created By ID':
      return 'Created By'
    case 'Contact ID':
      return 'Contact Name'
    case 'Account ID':
      return 'Account Name'
    default:
      return fieldName
  }
}
const ProfileMultiSelect = ({
  profiles,
  selections,
  onSelect,
  menuWidth = '428px',
  maxWidth = '428px',
  placeholder = 'Profiles to update',
}: any) => {
  const [term, setTerm] = useState('')

  const filteredProfiles = profiles.filter((profile: any) => {
    const searchTerm = term.toLowerCase()

    return profile?.full_name.toLowerCase().includes(searchTerm)
  })
  return (
    <Popover
      placement='bottom'
      onClose={() =>
        setTimeout(() => {
          setTerm('')
        }, 500)
      }
    >
      <PopoverTrigger>
        <Selection
          showPlaceholder={selections.length === 0}
          selections={selections}
          maxWidth={maxWidth}
        >
          {selections.length > 0 && profiles?.length > 0 ? (
            <PillsWrapper>
              {selections.map((selection: any, index: any) => {
                const profile = profiles?.find((profile: any) => {
                  return profile.id === selection
                })

                const zIndex = 0

                return (
                  <Pill
                    key={selection}
                    color={'#F0F0F0'}
                    style={{
                      marginLeft: index === 0 ? 0 : `5px`,
                      zIndex: zIndex,
                    }}
                  >
                    {truncateString(
                      fieldLabelText(
                        profile?.full_name
                          .replace('%3A', ':')
                          .replace('%2E', '.')
                          .replace('%28', '(')
                          .replace('%29', ')')
                          .replace('%26', '&'),
                      ),
                      28,
                    )}
                  </Pill>
                )
              })}
            </PillsWrapper>
          ) : (
            <div
              style={{
                color: '#A7B5C4',
                fontSize: '12.5px',
                marginLeft: '15px',
                marginTop: '5px',
              }}
            >
              {placeholder}
            </div>
          )}
        </Selection>
      </PopoverTrigger>
      <PopoverContent
        marginTop={'5px'}
        width={menuWidth}
        maxHeight={'280px'}
        boxShadow='0 8px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15)'
        overflowY='auto'
        _focus={{
          boxShadow:
            '0 8px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15)',
        }}
      >
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <Search height={15} width={15} color='#858585' />
          </InputLeftElement>
          <Input
            value={term}
            onChange={e => {
              setTerm(e.target.value)
            }}
            sx={{
              _focus: {
                boxShadow: 'none',
                borderColor: 'rgb(226, 232, 240)',
              },
              _hover: {
                boxShadow: 'none',
                borderColor: 'rgb(226, 232, 240)',
              },
            }}
            borderRadius={'none'}
            borderTop='none'
            borderLeft='none'
            borderRight='none'
            fontSize='14px'
            placeholder='Search'
          />
        </InputGroup>
        {filteredProfiles.map((profile: any) => {
          return (
            <Option
              key={profile.id}
              onClick={() => {
                onSelect(profile.id)
              }}
              isSelected={selections.includes(profile.id)}
            >
              <div style={{ paddingBottom: '15px' }}>
                <span
                  style={{
                    paddingLeft: '10px',
                    position: 'absolute',
                  }}
                >
                  {truncateString(
                    fieldLabelText(
                      profile?.full_name
                        .replace('%3A', ':')
                        .replace('%2E', '.')
                        .replace('%28', '(')
                        .replace('%29', ')')
                        .replace('%26', '&'),
                    ),
                    32,
                  )}
                </span>
              </div>
              <Checkbox
                onChange={() => {
                  onSelect(profile.id)
                }}
                isChecked={selections.includes(profile.id)}
                sx={{
                  '.chakra-checkbox__control': {
                    height: '18px',
                    width: '18px',
                    borderRadius: '3px',
                    borderColor: 'black',
                    _checked: {
                      bg: 'black',
                      color: 'white',
                      borderColor: 'black',

                      svg: {
                        transform: 'scale(1.3)',
                      },

                      _hover: {
                        bg: 'black',
                        borderColor: 'black',
                      },
                    },
                    _hover: {
                      borderColor: 'black',
                    },
                    _focus: {
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </Option>
          )
        })}
      </PopoverContent>
    </Popover>
  )
}

export default ProfileMultiSelect
