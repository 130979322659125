import React, { useState } from 'react'
import Row from '../Layout/Row'
import { tw } from '@minupalaniappan/brise'
import styled from 'styled-components'
import { ContainerProps } from '../../types'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Popover } from 'react-tiny-popover'
import TableFilterBlock from './TableFilterBlock'
import { CustomFilter, RecordData } from './PipelineTable'
import styles from './tableFilters.module.css'
import ListViewOptionsMenu from './ListViewOptionsMenu'
import FilterCombinationDropdown from './FilterCombinationDropdown'
import { Flex } from '@chakra-ui/react'
import HighlightsMenu from './HighlightsMenu'
import { SortingState } from '@tanstack/react-table'

type TableFilterCollectionProps = {
  records: Array<RecordData>
  listViews: Array<any>
  teams: Array<any>
  teamsToColors: any
  onSaveListView: (
    name: string,
    isDefault: boolean,
    isCreate: boolean,
    remove: boolean,
    isOrgWide: boolean,
    teamIds: any[],
  ) => any
  selectedListViewId: string | undefined
  selected: Array<any>
  updateListViews: (listViews: Array<any>, fields: Array<any>) => void
  user: any
  users: any[]
} & TableFilterResultsProps &
  TableFilterSegmentCollectionProps &
  TableHighlightsProps &
  TableSortingProps

type TableFilterResultsProps = {
  objectType: string
  foundItems: number
  totalItems: number
  isFetchingMore: boolean
}

const FoundItems = tw.div<ContainerProps>`
  !text-gray-500
  text-sm
  font-normal
  whitespace-nowrap	
`

const FoundItemsBolded = tw.span<ContainerProps>`
  font-semibold
  text-black
`

export const StartAddingFiltersButton = styled.div`
  padding-left: 14px;
  padding-right: 14px;
  font-size: 12px;
  margin-top: 1px;
`

export type TableFilterSegmentProps = {
  user: any
  teams: any[]
  users: any[]
  filters: CustomFilter[]
  filter: CustomFilter
  filterIndex: number
  key?: string
  forwardKey?: string
  label?: string
  onRemove?: Function
  setFilters: Function
  fields: Array<any>
  filterJoin?: string
  objectType?: string
} & ContainerProps

type TableFilterSegmentCollectionProps = {
  user: any
  users: any[]
  fields: any[]
  filters: CustomFilter[]
  setFilters: Function
  onClearFilters: () => void
  onRemove: Function
  filterJoin: 'and' | 'or' | string
  setFilterJoin: (value: 'and' | 'or' | string) => void
  teams: Array<any>
  selectedListViewId: string | undefined
  listViews: Array<any>
  objectType: string
} & ContainerProps

type TableHighlightsProps = {
  highlights: CustomFilter[]
  setHighlights: Function
  onRemoveHighlights: Function
}

type TableSortingProps = {
  sorting: SortingState
}

export type TableFilterProps = {
  isLast?: boolean
  isFirst?: boolean
  isIsolated?: boolean
} & ContainerProps

const TableFilterCell = tw.div<TableFilterProps>`
  ${props => (props.isFirst ? 'rounded-bl-md rounded-tl-md' : '')}
  ${props => (props.isLast ? 'rounded-tr-md rounded-br-md p-1' : '')}
  ${props => (props.isIsolated ? 'rounded-md p-1' : '')}
  cursor-pointer
  bg-[#F8F8F8]
  !h-[24px]
  border-r border-r-[#F8F8F8] border-r-2
  pr-1
  flex
  items-center
  text-[13px]
`

function formatDate(date: any) {
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day

  return `${month}/${day}/${year}`
}

export function getComparatorChipString(comparator: string, value?: string) {
  switch (comparator) {
    case '=':
      return 'is'
    case '!=':
      return 'is not'
    case 'contains':
      return 'includes'
    case 'last_n_days':
      return `in last ${value} days`
    case 'next_n_days':
      return `in next ${value} days`
    case 'last_n_months':
      return `in last ${value} months`
    case 'next_n_months':
      return `in next ${value} months`
    case 'last_n_quarters':
      return `in last ${value} quarters`
    case 'next_n_quarters':
      return `in next ${value} quarters`
    case 'is_not_blank':
      return 'is not'
    case 'greater_than_percent':
      return `is more than ${value}% of`
    case 'less_than_percent':
      return `is less than ${value}% of`
    case 'today':
      return `is today`
    case 'not_today':
      return `is not today`
    case 'before_today':
      return `is before today`
    case 'after_today':
      return `is after today`
    case 'this_week':
      return 'is within this week'
    case 'this_month':
      return `is within this month`
    case 'this_quarter':
      return `is within this quarter`
    case 'this_year':
      return `is within this year`
    case 'next_month':
      return `is within next month`
    case 'next_quarter':
      return `is within next quarter`
    case 'more_than_n_days_ago':
      return `is more than ${value} days ago`
    case 'less_than_n_days_ago':
      return `is less than ${value} days ago`
    default:
      return comparator
  }
}

export function formatConditionValue(value: any) {
  if (value instanceof Date) {
    return formatDate(value)
  }

  if (value?.length === 0) {
    return 'BLANK'
  }

  if (value === 'true' || value === 'false') {
    return value === 'true' ? 'Yes' : 'No'
  }

  return value
}

export const TableFilterSegment = (props: TableFilterSegmentProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <Row y='center'>
      <Popover
        {...{
          isOpen: isPopoverOpen,
          padding: 10,
          containerClassName: 'z-10',
          transformMode: 'relative',
          positions: [(props.filterIndex || 0) > 1 ? 'bottom' : 'right'],
          clickOutsideCapture: true,
          onClickOutside: e => {
            e.preventDefault()

            setIsPopoverOpen(false)
          },
          content: (
            <TableFilterBlock
              {...{
                user: props.user,
                users: props.users,
                teams: props.teams,
                fields: props.fields,
                filters: props.filters,
                setFilters: props.setFilters,
                selectedFilter: props.filter,
                selectedFilterIndex: props.filterIndex,
                objectType: props.objectType,
                onSubmit: () => setIsPopoverOpen(false),
              }}
            />
          ),
        }}
      >
        <div
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          style={{ display: 'flex' }}
        >
          {props.filter.conditions.map((condition, index) => (
            <React.Fragment key={index}>
              <TableFilterCell
                isFirst={index === 0}
                className={
                  props.filter.conditions.length > 0 && index != 0
                    ? 'pl-0 whitespace-nowrap'
                    : 'pl-2 whitespace-nowrap'
                }
              >
                {props.filterJoin &&
                  props.filterJoin != 'and' &&
                  props.filterJoin != 'or' &&
                  index === 0 && (
                    <p
                      style={{
                        marginRight: '2px',
                        fontFamily: 'monospace',
                        color: '#858585',
                      }}
                    >
                      {`[${(props.filterIndex + 1).toString()}]`}
                    </p>
                  )}
                {props?.filter?.key === 'OwnerId'
                  ? 'Owner'
                  : props?.filter?.key?.includes('teamOwned')
                  ? `Owned by ${
                      props.teams?.find(
                        (team: any) =>
                          team.id ===
                          props?.filter?.key.replace('teamOwned-', ''),
                      )?.name
                    } members`
                  : props.filter.label}
              </TableFilterCell>
              <TableFilterCell
                style={{
                  color: '#858585',
                  fontWeight: '500',
                  whiteSpace: 'nowrap',
                }}
              >
                {getComparatorChipString(condition.comparator, condition.value)}
              </TableFilterCell>
              {![
                'last_n_days',
                'last_n_months',
                'last_n_quarters',
                'next_n_days',
                'next_n_months',
                'next_n_quarters',
                'today',
                'not_today',
                'before_today',
                'after_today',
                'this_week',
                'this_month',
                'this_quarter',
                'this_year',
                'next_month',
                'next_quarter',
                'more_than_n_days_ago',
                'less_than_n_days_ago',
              ].includes(condition.comparator) && (
                <TableFilterCell className='whitespace-nowrap'>
                  {['greater_than_percent', 'less_than_percent'].includes(
                    condition.comparator,
                  )
                    ? props.fields?.find(
                        (field: any) =>
                          field.name === condition.comparativeFieldName,
                      )?.label || condition.comparativeFieldName
                    : props?.filter?.key === 'RecordTypeId'
                    ? props.fields
                        ?.find((field: any) => field.name === 'RecordTypeId')
                        ?.picklist_values?.find(
                          (picklistValue: any) =>
                            picklistValue.value === condition.value,
                        )?.label || formatConditionValue(condition.value)
                    : props?.filter?.key === 'OwnerId'
                    ? props?.users?.find(
                        (user: any) => user.id === condition.value,
                      )?.name || condition.value
                    : props?.filter?.key?.includes('teamOwned')
                    ? ''
                    : formatConditionValue(condition.value)}
                </TableFilterCell>
              )}
              {props.filter.conditions.length > 0 &&
              index != props.filter.conditions.length - 1 ? (
                <TableFilterCell
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.filter.operator}
                </TableFilterCell>
              ) : (
                ''
              )}
            </React.Fragment>
          ))}
        </div>
      </Popover>
      <TableFilterCell isLast>
        <XMarkIcon
          className='w-4 h-4 stroke-gray-500'
          onClick={() => props.onRemove && props.onRemove(props.filter.id)}
        />
      </TableFilterCell>
    </Row>
  )
}

const TableFilterResults = (props: TableFilterResultsProps) => {
  return (
    <>
      {props.isFetchingMore && (
        <div className={styles.spinner} style={{ marginRight: '3px' }}></div>
      )}
    </>
  )
}

export type AddFilterProps = {
  user: any
  users: any[]
  teams: any[]
  fields: any[]
  filters: CustomFilter[]
  setFilters: Function
  onAddFilter: () => void
  objectType: string
} & ContainerProps

export type ClearFiltersProps = {
  onClearFilters: () => void
} & ContainerProps

const AddFilter = (props: AddFilterProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <TableFilterCell isIsolated className='!pr-[3px]'>
      <Popover
        {...{
          isOpen: isPopoverOpen,
          padding: 10,
          containerClassName: 'z-10',
          transformMode: 'relative',
          positions: [props?.filters?.length > 2 ? 'bottom' : 'right'],
          clickOutsideCapture: true,
          onClickOutside: e => {
            e.preventDefault()

            setIsPopoverOpen(false)
          },
          content: (
            <TableFilterBlock
              {...{
                user: props.user,
                users: props.users,
                teams: props.teams,
                fields: props.fields,
                filters: props.filters,
                setFilters: props.setFilters,
                onSubmit: () => setIsPopoverOpen(false),
                objectType: props.objectType,
              }}
            />
          ),
        }}
      >
        {props.filters.length > 0 ? (
          <PlusIcon
            className='w-4 h-4 stroke-gray-600'
            {...{
              onClick: () => setIsPopoverOpen(!isPopoverOpen),
            }}
          />
        ) : (
          <StartAddingFiltersButton
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            Add Filters
          </StartAddingFiltersButton>
        )}
      </Popover>
    </TableFilterCell>
  )
}

const ClearFilter = (props: ClearFiltersProps) => {
  return (
    <button
      style={{ color: '#5A5A5A' }}
      className='bg-white text-[12px] rounded-md px-2 py-1 leading-tight border-[#e4e4e7] border hover:bg-[#fafafa] whitespace-nowrap'
      {...{
        onClick: props.onClearFilters,
      }}
    >
      Clear Filters
    </button>
  )
}

const Divider = tw.div`
  !w-[1px]
  h-[24px]
  bg-gray-100
`

const TableFilterSegmentCollection = tw(
  (props: TableFilterSegmentCollectionProps) => {
    return (
      <Row y='center' gap='small' grow>
        {props.filters.length > 0 ? (
          <>
            <Row
              y='center'
              className={`w-[90%] max-w-[90%] overflow-y-scroll ${styles.hideScrollbar}`}
              gap='small'
            >
              {props.filters.map((filter: CustomFilter, index: number) => (
                <TableFilterSegment
                  filter={filter}
                  filterIndex={index}
                  forwardKey={filter.key}
                  key={filter.key + '-' + filter.id}
                  onRemove={props.onRemove}
                  fields={props.fields}
                  filters={props.filters}
                  setFilters={props.setFilters}
                  users={props.users}
                  teams={props.teams}
                  user={props.user}
                  filterJoin={props.filterJoin}
                  objectType={props.objectType}
                />
              ))}
            </Row>
            <Divider />
          </>
        ) : (
          ''
        )}
        <AddFilter
          {...{
            fields: props.fields,
            filters: props.filters,
            setFilters: props.setFilters,
            selectedFilter: undefined,
            onAddFilter: () => {},
            teams: props.teams,
            user: props.user,
            users: props.users,
            objectType: props.objectType,
          }}
        />

        {props.filters.length > 1 && (
          <FilterCombinationDropdown
            currentListView={props.listViews.find(
              (listView: any) => listView.id === props.selectedListViewId,
            )}
            selectedOption={props.filterJoin}
            onChange={value => {
              props.setFilterJoin(value)
            }}
          />
        )}
        {props.filters.length > 0 && (
          <ClearFilter
            {...{
              onClearFilters: () => props.onClearFilters(),
            }}
          />
        )}
      </Row>
    )
  },
)``

const TableFilterCollection = (props: TableFilterCollectionProps) => {
  return (
    <>
      <Row grow y='center' between spacingX='small' spacingY='small'>
        <Row grow className='max-w-[75%]'>
          <TableFilterSegmentCollection {...props} />
        </Row>

        <Row y='center' gap='small'>
          <TableFilterResults
            {...props}
            totalItems={props.totalItems || 0}
            isFetchingMore={props.isFetchingMore}
          />
        </Row>

        <Flex gap={3}>
          <HighlightsMenu {...props} />
          <ListViewOptionsMenu {...props} />
        </Flex>
      </Row>
    </>
  )
}

export default TableFilterCollection
