import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Spacer,
  Spinner,
} from '@chakra-ui/react'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'
import { get } from 'lodash'
import React, { HTMLProps, useContext, useEffect, useState } from 'react'
import { StateStore } from '../Store'
import styled from 'styled-components'
import { Users, Type } from 'react-feather'
import Api from '../../lib/api'
import { Select } from 'chakra-react-select'
import FieldMultiSelect from '../Alerts/V2/FieldMultiSelect'
import { tw } from '@minupalaniappan/brise'
import {
  TextareaProps as ChakraTextAreaProps,
  Textarea as ChakraTextArea,
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { debounce } from 'lodash'

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
`

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 2px;
  color: #646464;
`
const selectStyles = (width?: string) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: 'none',
    fontSize: '0.875rem',
    // minWidth: '150px',
    width: width ? width : '150.66px', // Make control responsive to parent container's width
    fontWeight: '500',
    minHeight: '36px',
    padding: '0 8px',
    cursor: 'pointer',
    backgroundColor: 'white',
    marginTop: '12px',
    borderRadius: '4px', // Match your border radius
    border: '1px solid #e4e4e7',
    '&:hover': {
      border: '1px solid #e4e4e7!important',
      backgroundColor: '#fafafa',
    },
    '&:focus': { border: '1px solid  #e4e4e7 !important' },
    '&:focus-within': { border: '1px solid  #e4e4e7 !important' },
    '&:active': { border: '1px solid  #e4e4e7 !important' },
    outline: 'none',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0 6px',
    fontSize: '0.875rem',
    fontWeight: '500',
    borderRadius: '10px',
    overflow: 'hidden', // Add this to handle the overflow of elements
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: '#333', // Placeholder text color
    fontSize: '13px', // Placeholder font size
    fontWeight: '500',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    alignItems: 'center',
    position: 'relative',
    bottom: '1px',
    background: 'none !important',
    svg: {
      strokeWidth: '1px !important',
    },
    paddingLeft: '0px',
    paddingRight: '0px',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    border: '.2px solid #E0E0E0',
    borderTop: '0px',
    boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    fontSize: '13px',
    backgroundColor: 'white',
    overflow: 'hidden',
    width: '250px',
    zIndex: 9999999,
  }),
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      background: '#F3F3F3',
      border: '1px solid #EFEFEF',
    }
  },
  option: (provided: any, state: any) => ({
    ...provided,
    color: 'black',
    backgroundColor: state.isSelected ? 'rgb(244, 244, 244)' : 'white',
    '&:hover': {
      backgroundColor: 'rgb(244, 244, 244)',
    },
    fontSize: '13px',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: '#333',
    fontSize: '0.875rem',
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
})

const SaveButton = tw.div<HTMLProps<HTMLDivElement>>`
              cursor-pointer
              rounded-[6px]
              bg-[black]
              text-center
              text-white
              hover:bg-[#282828]
              px-5
              py-2
              text-[14px]
              font-[500]
              ${props =>
                props.disabled ? `opacity-50 pointer-events-none` : ''}
              `

const DeleteButton = styled.div`
  border-radius: 6px;
  background-color: #ebebeb;
  color: #1a202c;
  width: 150px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 15px;
`

const UpdateFieldDescriptionsDialogue = () => {
  const { state, dispatch } = useContext(StateStore)
  const modalKey = get(state, 'modal.key', '') as string
  let isOpen = ['updateFieldDescriptions'].includes(modalKey)
  const [objects, setObjects] = useState<Array<any>>([])
  const [profiles, setProfiles] = useState<Array<any>>([])
  const [fields, setFields] = useState<Array<any>>([])
  const [selectedObject, setSelectedObject] = useState('')
  const [selectedFields, setSelectedFields] = useState([])
  const [selectedAction, setSelectedAction] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [results, setResults] = useState<any>({})
  const [isDeploying, setIsDeploying] = useState(false)
  const [isGeneratingDescriptions, setIsGeneratingDescriptions] =
    useState(false)
  const [fieldMetadatas, setFieldMetadatas] = useState<Array<any>>([])
  const toast = useToast()
  const user = get(state, 'user')

  const baseRef = get(state, 'dialogue.rootRef.ref')

  const onClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  useEffect(() => {
    Api.salesforce.objects.getObjects({}).then(({ objects }) => {
      console.log('objects', objects)
      setObjects(objects)
    })

    Api.salesforce.profiles.getProfiles({}).then(({ profiles }) => {
      console.log('profiles', profiles)
      setProfiles(profiles)
    })
  }, [])

  useEffect(() => {
    if (!!selectedObject) {
      Api.salesforce.objects.objectName.fields
        .getFields({ objectName: selectedObject, include_help_text: true })
        .then(({ fields }) => {
          if (fields === undefined) {
            return
          }
          console.log('fields', fields)

          setFields(fields)

          Api.salesforce.objects.objectName.customFields
            .getCustomFieldMetadata({
              objectName: selectedObject,
              fieldNames: fields.map((f: any) => f.name),
            })
            .then(({ fields }) => {
              if (fields === undefined) {
                return
              }
              console.log('field metadata', fields)

              setFieldMetadatas(fields)
            })
        })
    }
  }, [selectedObject])

  const handleFieldSelect = (field: any) => {
    setSelectedFields((prevFields: any) => {
      if (prevFields.includes(field)) {
        return prevFields.filter((f: any) => f !== field)
      }
      const updatedFields = [...prevFields, field]
      return updatedFields
    })
  }

  async function processFieldsInBatches(
    selectedFields: string[],
    fields: any[],
    selectedObject: string,
  ) {
    const batchSize = 5

    for (let i = 0; i < selectedFields.length; i += batchSize) {
      const batch = selectedFields.slice(i, i + batchSize)

      await Promise.all(
        batch.map(async (fieldName: string) => {
          const field = fields.find((f: any) => f.name === fieldName)
          if (field) {
            await Api.salesforce.objects.objectName.customFields
              .generateFieldMetaText({
                prompt: field.label,
                objectName: selectedObject,
                fieldType: field.type,
                formula: fieldMetadatas?.find((f: any) => f.name === fieldName)
                  ?.metadata?.formula,
              })
              .then(({ description, help_text }) => {
                console.log(field.name, description, help_text)
                setResults((prev: any) => {
                  return { ...prev, [field.name]: description }
                })
              })
          }
        }),
      )

      if (i + batchSize < selectedFields.length) {
        // Wait for 2 seconds before processing the next batch
        await delay(2000)
      }
    }
  }

  const handleSubmit = async () => {
    setSubmitted(true)
    setIsGeneratingDescriptions(true)
    await processFieldsInBatches(selectedFields, fields, selectedObject)
    setIsGeneratingDescriptions(false)
  }

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  const deployUpdates = async () => {
    setIsDeploying(true)
    const fieldNames = Object.keys(results)

    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i]

      try {
        const response =
          await Api.salesforce.objects.objectName.customFields.editDescription({
            fieldName: fieldName,
            objectName: selectedObject,
            description: results[fieldName],
          })
      } catch (error) {
        toast({
          description: `An error occurred while updating the field description for ${fieldName}`,
          status: 'error',
          duration: 4000,
          position: 'top',
        })
      }

      // Introduce a 3-second delay after every 5 fields
      if ((i + 1) % 5 === 0 && i + 1 < fieldNames.length) {
        await delay(2000)
      }
    }

    toast({
      description: `Succesfully updated the description for ${fieldNames.length} fields.`,
      status: 'success',
      duration: 4000,
      position: 'top',
    })

    onClose()
  }

  const debouncedOnChange = useCallback(
    debounce((e, field) => {
      const newResults = { ...results, [field]: e.target.value }
      setResults(newResults)
    }, 2000),
    [results],
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      portalProps={{
        containerRef: baseRef,
      }}
      closeOnEsc
      closeOnOverlayClick
    >
      <ModalOverlay />

      <ModalContent
        minHeight={'275px'}
        borderRadius={'10px'}
        style={{ minWidth: '700px' }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Type size={26} />
            <Title style={{ marginLeft: '16px', marginBottom: '2px' }}>
              Generate Field Descriptions
            </Title>
          </div>

          <Subtitle>Generate descriptions for fields in bulk</Subtitle>
          <div style={{ marginTop: '30px', marginBottom: '15px' }}>
            <Table>
              <Tbody>
                <Tr>
                  <Td
                    style={{
                      fontSize: '13px',
                      color: '#1E2430',
                      fontWeight: 500,
                      width: '100px',
                      border: 'none',
                      paddingLeft: '0px',
                    }}
                    py={0}
                  >
                    Object
                  </Td>
                  <Td>
                    <Select
                      chakraStyles={selectStyles()}
                      onChange={(e: any) => {
                        setSelectedObject(e.value)
                      }}
                      isDisabled={false}
                      placeholder='Select object'
                      options={objects?.map((obj: any) => ({
                        label: obj.label,
                        value: obj.name,
                      }))}
                      isSearchable={true}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    style={{
                      fontSize: '13px',
                      color: '#1E2430',
                      fontWeight: 500,
                      width: '100px',
                      border: 'none',
                      paddingLeft: '0px',
                    }}
                    py={0}
                  >
                    Fields
                  </Td>
                  <Td>
                    <FieldMultiSelect
                      fields={fields || []}
                      selections={selectedFields}
                      onSelect={handleFieldSelect}
                      placeholder='Fields to update access for'
                      menuWidth={'370px'}
                    />
                  </Td>
                </Tr>
                {submitted &&
                  selectedFields.map((field: any) => {
                    return (
                      <Tr key={field}>
                        <Td
                          style={{
                            fontSize: '13px',
                            color: '#1E2430',
                            fontWeight: 500,
                            minWidth: '100px',
                            maxWidth: '205px',
                            border: 'none',
                            paddingLeft: '0px',
                          }}
                          py={0}
                        >
                          {`${
                            fields.find((f: any) => f.name === field)?.label ??
                            field
                          } (${field})`}
                        </Td>
                        <Td>
                          {!!results[field] ? (
                            <ChakraTextArea
                              defaultValue={results[field] || ''}
                              onChange={e => debouncedOnChange(e, field)}
                              fontSize={'sm'}
                              minWidth={'428px'}
                              maxWidth={'428px'}
                            />
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '428px',
                                justifyContent: 'left',
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: '6px',
                                  fontSize: '13px',
                                  color: '#1E2430',
                                }}
                              >
                                Loading ...
                              </div>
                            </div>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <SaveButton
            onClick={() => {
              if (submitted) {
                deployUpdates()
              } else {
                handleSubmit()
              }
            }}
            style={{ width: '100%' }}
            disabled={
              !selectedObject ||
              selectedFields?.length === 0 ||
              isDeploying ||
              isGeneratingDescriptions
            }
          >
            {submitted && !isGeneratingDescriptions && !isDeploying
              ? 'Deploy to Salesforce'
              : submitted && isGeneratingDescriptions
              ? 'Generating Descriptions'
              : isDeploying
              ? 'Deploying to Salesforce'
              : 'Generate Descriptions'}
          </SaveButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdateFieldDescriptionsDialogue
