import { useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { StateStore } from '../Store'
import { get, isEmpty } from 'lodash'
import FieldDialogue from './FieldDialogue'
import Api from '../../lib/api'
import { FormulaFieldReturnTypeMap } from '../constants'

type EditFieldProps = {
  onSubmit?: (values: any) => void
  fieldName?: string
  fieldType?: string
  objectName?: string
}

const EditFieldDialogue = (props: EditFieldProps) => {
  const { state, dispatch } = useContext(StateStore)
  const [customFieldLoading, setCustomFieldLoading] = useState(false)
  const [fetchedCustomField, setFetchedCustomField] = useState({}) as any

  const initialValues = {
    fieldName: get(fetchedCustomField, 'name', ''),
    objectName: get(fetchedCustomField, 'object_name', ''),
    label: get(fetchedCustomField, 'label', ''),
    description: get(fetchedCustomField, 'description', '') || '',
    dataType: get(fetchedCustomField, 'type', ''),
    helpText: get(fetchedCustomField, 'help_text', '') || '',
    pageLayouts: [],
    allPageLayouts: true,
    defaultValue: get(fetchedCustomField, 'default_value', '') || false,
    picklistValues: get(fetchedCustomField, 'picklist_values', []).map(
      (value: any) => ({ label: value.value, value: value.value }),
    ),
    prompt: '',
    formula: get(fetchedCustomField, 'formula', '') || '',
    formulaFieldType: get(fetchedCustomField, 'formula_field_type', '')
      ? FormulaFieldReturnTypeMap[
          get(
            fetchedCustomField,
            'formula_field_type',
            '',
          ) as keyof typeof FormulaFieldReturnTypeMap
        ]
      : '',
    relatedTo: get(fetchedCustomField, 'related_to', '') || '',
    customRollup: get(fetchedCustomField, 'custom_rollup'),
  }

  const isOpen = (get(state, 'modal.key', '') as string) === 'editField'
  const fieldData = get(state, 'modal.data', {})

  const toast = useToast()

  useEffect(() => {
    if (!isEmpty(fetchedCustomField)) {
      return
    }

    setCustomFieldLoading(true)

    Api.salesforce.objects.objectName.customFields
      .getCustomField({
        customFieldName: get(fieldData, 'field_name', ''),
        objectName: get(fieldData, 'object_name', ''),
      })
      .then(e => {
        setFetchedCustomField(e)
        setCustomFieldLoading(false)
      })
  }, [])

  const handleSubmit = async (values: any) => {
    const picklistValues = (values.picklistValues || []).map(
      (value: any) => value.value,
    )

    await Api.salesforce.objects.objectName.customFields
      .editCustomField({
        fieldName: initialValues.fieldName,
        objectName: initialValues.objectName,
        fieldType:
          values.dataType === 'picklist_multi_select'
            ? 'multipicklist'
            : values.dataType,
        newFieldName: values.fieldName,
        label: values.label,
        description: values.description,
        helpText: values.helpText,
        defaultValue: values.defaultValue,
        formula: values.formula,
        formulaFieldType: values.formulaFieldType,
        values: picklistValues,
        relatedTo: values.relatedTo,
        externalId: values.isExternalId,
        required: values.isRequired,
      })
      .then(e => {
        if (e['errors']) {
          toast({
            description: e['errors'].map((e: any) => e.message).join('\n'),
            status: 'error',
            duration: 4000,
            position: 'top',
          })
        } else {
          toast({
            description: 'Deploying edit to your Salesforce environment',
            status: 'success',
            duration: 4000,
            position: 'top',
          })

          if (values?.customRollup) {
            handleClose()

            dispatch({
              type: 'TOGGLE_MODAL',
              payload: {
                key: 'rollupField',
                data: {
                  objectName: initialValues.objectName,
                  fieldName: initialValues.fieldName,
                  parentFieldType: values.dataType,
                },
              },
            })
          } else {
            handleClose()
          }
        }
      })
  }

  const handleClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  const getModalHeaderText = () => {
    if (isEmpty(fetchedCustomField)) {
      return 'Edit Field'
    }

    return `Edit ${fetchedCustomField.name} (${fetchedCustomField.type})`
  }

  return (
    <FieldDialogue
      onSubmit={handleSubmit}
      initialValues={initialValues}
      onClose={handleClose}
      isObjectFieldDisabled={true}
      showPageLayouts={false}
      getModalHeaderText={getModalHeaderText}
      isOpen={isOpen}
      customFieldLoading={customFieldLoading}
    />
  )
}

export default EditFieldDialogue
