import React, { HTMLProps, useEffect, useState } from 'react'
import { tw } from '@minupalaniappan/brise'
import Row from '../Layout/Row'
import { ContainerProps } from '../../types'
import Select from '../Inputs/Select'
import Form from '../Form'
import * as yup from 'yup'
import { FieldArray } from 'formik'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Plus } from 'react-feather'
import TextInput from '../Inputs/TextInput'
import DatePicker from 'react-datepicker'
import { get, startCase } from 'lodash'
import { CustomFilter } from './PipelineTable'
import styled from 'styled-components'
import datePickerStyles from './DatePicker.module.css'
import { formatDateToYYYYMMDD, parseLocalDate, sortFields } from './utils'
import { TRUE } from 'sass'
import Api from '../../lib/api'

const textComparators = ['equals', 'does_not_equal', 'contains', 'is_not_blank']
const picklistComparators = ['equals', 'does_not_equal', 'is_not_blank']
const numberComparators = [
  'equals',
  'does_not_equal',
  'greater_than',
  'less_than',
  'less_than_percent',
  'greater_than_percent',
  'is_not_blank',
]
const dateComparators = [
  'equals',
  'greater_than',
  'less_than',
  'is_not_blank',
  'today',
  'not_today',
  'before_today',
  'after_today',
  'this_week',
  'this_month',
  'this_quarter',
  'this_year',
  'next_month',
  'next_quarter',
  'last_n_days',
  'next_n_days',
  'last_n_months',
  'next_n_months',
  'last_n_quarters',
  'next_n_quarters',
  'more_than_n_days_ago',
  'less_than_n_days_ago',
]

const COMPARATOR_MAP: Record<string, Array<string>> = {
  string: textComparators,
  id: textComparators,
  text: textComparators,
  boolean: textComparators,
  textarea: textComparators,
  reference: textComparators,
  picklist: picklistComparators,
  number: numberComparators,
  currency: numberComparators,
  percent: numberComparators,
  double: numberComparators,
  int: numberComparators,
  date: dateComparators,
  datetime: dateComparators,
  email: textComparators,
}

type Filter = {
  fieldName: string
  conditions: Array<{
    condition: 'where'
    comparator: 'is' | 'greater_than' | 'less_than'
    fieldType:
      | 'number'
      | 'text'
      | 'date'
      | 'picklist'
      | 'id'
      | 'reference'
      | 'string'
      | 'percent'
      | 'email'
    operator?: 'and' | 'or'
  }>
}

type TableFilterBlockProps = {
  fields: any[]
  filters: CustomFilter[]
  setFilters: Function
  onSubmit: () => void
  objectType?: string
  advancedFilter?: boolean
  isHighlights?: boolean
  selectedFilter?: CustomFilter
  selectedFilterIndex?: number
  teams: any[]
  user: any
  users: any[]
}

const AddFilter = tw.div<HTMLProps<HTMLDivElement>>`
  cursor-pointer
  rounded-[5.5px]
  bg-black
  text-center
  text-white
  hover:bg-[#474747]
  px-3.5
  py-1.5
  text-xs
  font-medium

  ${props => (props.disabled ? `opacity-50 pointer-events-none` : '')}
`

const SecondaryButton = tw.div<HTMLProps<HTMLDivElement>>`
  cursor-pointer
  rounded-[5.5px]
  bg-white
  text-center
  text-black
  border
  border-[#e4e4e7]
  px-3.5
  py-1.5
  text-xs
  font-medium
  hover:bg-[#fafafa]
`

const TertiaryButton = tw.div<HTMLProps<HTMLDivElement>>`
  cursor-pointer
  rounded-[5.5px]
  bg-white
  text-center
  text-black
  px-2
  py-1.5
  text-xs
  font-[500]
  hover:bg-[#fafafa]
`

const CaptionText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #5a5a5a;
  margin-right: 15px;
`

const FilterRow = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 10px;
  }
`

const TableFilterBlockContainer = styled.div<{ $isHighlights?: boolean }>`
  background-color: white;
  min-width: 710px;
  max-width: 710px;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-top: 0px;
  padding-top: 20px;
  padding-bottom: 20px;

  ${props =>
    !props.$isHighlights &&
    `
  margin-left: 10px;
  border: 1px solid #e4e4e7;
  `}
`

const TextFilter = tw.div<ContainerProps>`
  text-xs
  text-gray-800
  whitespace-nowrap
`

function getComparatorSymbol(comparator: string) {
  switch (comparator) {
    case 'equals':
      return '='
    case 'does_not_equal':
      return '!='
    case 'greater_than':
      return '>'
    case 'less_than':
      return '<'
    default:
      return comparator
  }
}

function getComparatorFilterStringFromSymbol(comparator: string) {
  switch (comparator) {
    case '=':
      return 'equals'
    case '!=':
      return 'does_not_equal'
    case '>':
      return 'greater_than'
    case '<':
      return 'less_than'
    default:
      return comparator
  }
}

function addFilter(filters: any[], fields: any[]): CustomFilter[] {
  const newFilters: CustomFilter[] = filters.map(f => {
    const field = fields.find(field => field.name === f.fieldName)

    const conditions = f.conditions.map(
      (c: {
        comparator: string
        fieldValue: any
        comparativeFieldName: string | undefined
      }) => ({
        comparator: getComparatorSymbol(c.comparator),
        value:
          f.fieldName === 'Owned by me'
            ? 'Owned by me'
            : f.fieldName === 'Open opportunities'
            ? 'Open opportunities'
            : f.fieldName?.includes('teamOwned')
            ? f.fieldName
            : c.fieldValue instanceof Date
            ? formatDateToYYYYMMDD(c.fieldValue)
            : c.fieldValue
            ? c.fieldValue
            : field.type === 'boolean'
            ? 'false'
            : c.fieldValue === 0
            ? 0
            : '',
        comparativeFieldName: c.comparativeFieldName,
      }),
    )

    const fieldLabel = field?.label
    const randomId = Math.floor(Math.random() * 500) + 1

    return {
      label: fieldLabel,
      key: f.fieldName,
      conditions: conditions,
      operator: f.operator,
      id: randomId,
    }
  })

  return newFilters
}

function convertAddedFilterConditionsForInitialValues(
  selectedFilter: CustomFilter,
) {
  return (
    selectedFilter?.conditions?.map((condition: any) => ({
      comparator: getComparatorFilterStringFromSymbol(condition.comparator),
      fieldValue: condition.value,
      comparativeFieldName: condition.comparativeFieldName,
    })) || [{}]
  )
}

function getTeamOwnerFilterOptions(teams: any[], user: any) {
  const teamIds = teams.reduce((ids, team) => {
    if (
      user.is_super_admin ||
      user.salesforce_user_id === team.manager_salesforce_user_id
    ) {
      ids.add(team.id)
      ;(team.sub_teams || []).forEach((subTeam: any) => ids.add(subTeam.id))
    }
    return ids
  }, new Set())

  const uniqueTeamIds = Array.from(teamIds)

  const teamOptions = uniqueTeamIds.map(teamId => ({
    value: `teamOwned-${teamId}`,
    label: `Owned by ${
      teams.find((team: any) => team.id === teamId)?.name
    } members`,
  }))

  return teamOptions || []
}

const TableFilterBlock = (props: TableFilterBlockProps) => {
  const sortedFields = sortFields(props.fields)
  const [selectedField, setSelectedField] = useState<any>(undefined)
  const [defaultOptions, setDefaultOptions] = useState<any[]>([])

  async function getDeafultFieldValueOptions(field: any) {
    const options = await Api.salesforce.data
      .queryRecords({
        objectName: field.reference_to,
        limit: 10,
        offset: 0,
        filters: undefined,
      })
      .then(data => {
        const options = data.records.map((record: any) => {
          return {
            label: record['Name'] ?? record['Id'],
            value: record['Id'],
          }
        })
      })
    return options
  }

  useEffect(() => {
    if (!!selectedField && !!selectedField.reference_to) {
      if (selectedField.reference_to === 'User') {
        const options = props.users.map((user: any) => {
          return {
            label: user.name,
            value: user.id,
          }
        })
        setDefaultOptions(options)
      } else {
        const foundFilters = props.filters?.filter(
          filter => filter.key === selectedField.name,
        )
        const fieldValues = foundFilters
          ?.flatMap(filter =>
            filter.conditions.map(condition => condition.value),
          )
          .filter(value => value !== null && value !== undefined)

        Api.salesforce.data
          .queryRecords({
            objectName: selectedField.reference_to,
            limit: 10,
            offset: 0,
            filters:
              !!fieldValues && fieldValues?.length > 0
                ? `Id IN (${fieldValues.map(value => `'${value}'`).join(',')})`
                : undefined,
          })
          .then(data => {
            const options = data.records.map((record: any) => {
              return {
                label: record['Name'] ?? record['Id'],
                value: record['Id'],
              }
            })
            setDefaultOptions(options)
          })
      }
    }
  }, [selectedField])

  return (
    <TableFilterBlockContainer $isHighlights={props.isHighlights}>
      <Form
        className='w-full'
        {...{
          initialValues: {
            advancedFilter: props.advancedFilter || false,
            filters: [
              {
                fieldName: props.selectedFilter?.key || '',
                operator: props.selectedFilter?.operator || 'and',
                conditions: !!props.selectedFilter?.conditions
                  ? convertAddedFilterConditionsForInitialValues(
                      props.selectedFilter,
                    )
                  : [{}],
              },
            ],
          },
          validationSchema: yup.object().shape({
            advancedFilter: yup.boolean().optional(),
            filters: yup.array().of(
              yup.object().shape({
                fieldName: yup.string().required(),
                operator: yup.string().optional(),
                conditions: yup.array().of(
                  yup.object({
                    comparator: yup.string().required(),
                    fieldValue: yup.string().optional(),
                    comparativeFieldName: yup.string().optional(),
                  }),
                ),
              }),
            ),
          }),
          onSubmit: async values => {},
        }}
      >
        {formikProps => {
          return (
            <div>
              <FieldArray
                name='filters'
                render={arrHelper => (
                  <div>
                    {formikProps.values.filters.map(
                      (filter: Filter, index: number) => {
                        const foundField = props.fields.find(
                          field =>
                            field.name ===
                            formikProps.values.filters[index].fieldName,
                        )
                        setSelectedField(foundField)
                        const conditionType = foundField?.type
                        const referenceTo = foundField?.reference_to

                        return (
                          <Row key={index}>
                            <Row>
                              <FieldArray
                                name={`filters.${index}.conditions`}
                                render={conditionArrHelper => {
                                  return (
                                    <div>
                                      {filter.conditions?.map((c, g) => {
                                        return (
                                          <>
                                            <FilterRow key={g}>
                                              {g === 0 && (
                                                <Row y='center'>
                                                  <CaptionText>
                                                    Where
                                                  </CaptionText>
                                                  <div
                                                    style={{
                                                      width: '190px',
                                                      marginRight: '10px',
                                                    }}
                                                  >
                                                    <Select
                                                      name={`filters.${index}.fieldName`}
                                                      placeholder={'Field'}
                                                      {...{
                                                        options: [
                                                          {
                                                            value:
                                                              'Owned by me',
                                                            label:
                                                              'Owned by me',
                                                          },
                                                        ]
                                                          .concat(
                                                            props.objectType ===
                                                              'Opportunity'
                                                              ? [
                                                                  {
                                                                    value:
                                                                      'Open opportunities',
                                                                    label:
                                                                      'Open opportunities',
                                                                  },
                                                                ]
                                                              : [],
                                                          )
                                                          .concat(
                                                            props.teams
                                                              ?.length > 0
                                                              ? getTeamOwnerFilterOptions(
                                                                  props.teams,
                                                                  props.user,
                                                                )
                                                              : [],
                                                          )
                                                          .concat(
                                                            sortedFields?.map(
                                                              field => ({
                                                                value:
                                                                  field.name,
                                                                label:
                                                                  field.label,
                                                              }),
                                                            ) || [],
                                                          ),
                                                      }}
                                                    />
                                                  </div>
                                                </Row>
                                              )}
                                              {g > 0 && (
                                                <div
                                                  style={{
                                                    minWidth: '80px',
                                                    marginRight: '10px',
                                                    marginLeft: '163px',
                                                  }}
                                                >
                                                  <Select
                                                    name={`filters.${index}.operator`}
                                                    placeholder={'And'}
                                                    {...{
                                                      options: [
                                                        {
                                                          value: 'and',
                                                          label: 'And',
                                                        },
                                                        {
                                                          value: 'or',
                                                          label: 'Or',
                                                        },
                                                      ],
                                                    }}
                                                  />
                                                </div>
                                              )}

                                              <div
                                                style={{
                                                  minWidth: '140px',
                                                  maxWidth: '140px',
                                                  marginRight: '10px',
                                                }}
                                              >
                                                {formikProps.values.filters[
                                                  index
                                                ].fieldName !== 'Owned by me' &&
                                                  formikProps.values.filters[
                                                    index
                                                  ].fieldName !==
                                                    'Open opportunities' &&
                                                  !formikProps.values.filters[
                                                    index
                                                  ].fieldName?.includes(
                                                    'teamOwned',
                                                  ) && (
                                                    <Select
                                                      placeholder={'Select...'}
                                                      name={`filters.${index}.conditions.${g}.comparator`}
                                                      isDisabled={
                                                        typeof formikProps
                                                          .values.filters[
                                                          index
                                                        ] === 'undefined' ||
                                                        typeof formikProps
                                                          .values.filters[index]
                                                          .fieldName ===
                                                          'undefined'
                                                      }
                                                      {...{
                                                        options: !(
                                                          typeof formikProps
                                                            .values.filters[
                                                            index
                                                          ] === 'undefined' ||
                                                          typeof formikProps
                                                            .values.filters[
                                                            index
                                                          ].fieldName ===
                                                            'undefined'
                                                        )
                                                          ? COMPARATOR_MAP[
                                                              props.fields.find(
                                                                field =>
                                                                  field.name ===
                                                                  formikProps
                                                                    .values
                                                                    .filters[
                                                                    index
                                                                  ].fieldName,
                                                              )?.type || 'text'
                                                            ].map(
                                                              (
                                                                comparator: string,
                                                              ) => ({
                                                                value:
                                                                  comparator,
                                                                label:
                                                                  startCase(
                                                                    comparator,
                                                                  ),
                                                              }),
                                                            )
                                                          : undefined,
                                                      }}
                                                    />
                                                  )}
                                              </div>

                                              <div
                                                style={{
                                                  maxWidth: '260px',
                                                  minWidth: '260px',
                                                }}
                                              >
                                                {!(
                                                  typeof formikProps.values
                                                    .filters[index] ===
                                                    'undefined' ||
                                                  typeof formikProps.values
                                                    .filters[index]
                                                    .fieldName === 'undefined'
                                                ) &&
                                                [
                                                  'greater_than_percent',
                                                  'less_than_percent',
                                                ].includes(
                                                  get(
                                                    formikProps.values,
                                                    `filters.${index}.conditions.${g}.comparator`,
                                                  ),
                                                ) ? (
                                                  <>
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          width: '55px',
                                                          marginRight: '10px',
                                                        }}
                                                      >
                                                        <TextInput
                                                          type='number'
                                                          name={`filters.${index}.conditions.${g}.fieldValue`}
                                                          height={'32px'}
                                                          placeholder='%'
                                                        />
                                                      </div>
                                                      <CaptionText
                                                        style={{
                                                          marginRight: '10px',
                                                        }}
                                                      >
                                                        of
                                                      </CaptionText>
                                                      <div
                                                        style={{
                                                          width: '170px',
                                                        }}
                                                      >
                                                        <Select
                                                          name={`filters.${index}.conditions.${g}.comparativeFieldName`}
                                                          placeholder={'Field'}
                                                          {...{
                                                            options:
                                                              sortedFields?.map(
                                                                field => ({
                                                                  value:
                                                                    field.name,
                                                                  label:
                                                                    field.label,
                                                                }),
                                                              ) || [],
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : conditionType &&
                                                  [
                                                    'text',
                                                    'string',
                                                    'id',
                                                    'textarea',
                                                    'email',
                                                  ].includes(conditionType) ? (
                                                  <TextInput
                                                    name={`filters.${index}.conditions.${g}.fieldValue`}
                                                    height={'32px'}
                                                    style={{
                                                      borderRadius: '4px',
                                                      height: '32px',
                                                      fontSize: '12px',
                                                    }}
                                                  />
                                                ) : [
                                                    'number',
                                                    'currency',
                                                    'percent',
                                                    'double',
                                                    'int',
                                                  ].includes(conditionType) ? (
                                                  <TextInput
                                                    type='number'
                                                    name={`filters.${index}.conditions.${g}.fieldValue`}
                                                    height={'32px'}
                                                  />
                                                ) : [
                                                    'date',
                                                    'datetime',
                                                  ].includes(conditionType) &&
                                                  ![
                                                    'today',
                                                    'not_today',
                                                    'before_today',
                                                    'after_today',
                                                    'this_week',
                                                    'this_month',
                                                    'this_quarter',
                                                    'this_year',
                                                    'next_month',
                                                    'next_quarter',
                                                  ].includes(
                                                    get(
                                                      formikProps.values,
                                                      `filters.${index}.conditions.${g}.comparator`,
                                                    ),
                                                  ) ? (
                                                  [
                                                    'last_n_days',
                                                    'next_n_days',
                                                    'last_n_months',
                                                    'next_n_months',
                                                    'last_n_quarters',
                                                    'next_n_quarters',
                                                    'more_than_n_days_ago',
                                                    'more_than_n_days_from_now',
                                                    'less_than_n_days_ago',
                                                    'less_than_n_days_from_now',
                                                  ].includes(
                                                    get(
                                                      formikProps.values,
                                                      `filters.${index}.conditions.${g}.comparator`,
                                                    ),
                                                  ) ? (
                                                    <TextInput
                                                      type='number'
                                                      name={`filters.${index}.conditions.${g}.fieldValue`}
                                                      height={'32px'}
                                                    />
                                                  ) : (
                                                    <DatePicker
                                                      selected={(() => {
                                                        const dateValue = get(
                                                          formikProps.values,
                                                          `filters.${index}.conditions.${g}.fieldValue`,
                                                        )
                                                        if (
                                                          dateValue instanceof
                                                          Date
                                                        ) {
                                                          return dateValue
                                                        } else if (
                                                          typeof dateValue ===
                                                          'string'
                                                        ) {
                                                          return parseLocalDate(
                                                            dateValue,
                                                          )
                                                        }
                                                        return new Date()
                                                      })()}
                                                      onChange={date =>
                                                        formikProps.setFieldValue(
                                                          `filters.${index}.conditions.${g}.fieldValue`,
                                                          date,
                                                        )
                                                      }
                                                      calendarClassName={`${datePickerStyles.calendar} ${datePickerStyles.header}`}
                                                      className='border p-1 rounded relative bottom-[1px] border-gray-200 px-2 focus:outline-none w-full placeholder:text-xs text-xs py-[6px] h-[32px] mt-[1px]'
                                                      name={`filters.${index}.conditions.${g}.fieldValue`}
                                                      placeholderText='Select Date'
                                                    />
                                                  )
                                                ) : conditionType ===
                                                  'boolean' ? (
                                                  <Select
                                                    name={`filters.${index}.conditions.${g}.fieldValue`}
                                                    className='min-w-[150px]'
                                                    options={[
                                                      {
                                                        value: 'true',
                                                        label: 'Yes',
                                                      },
                                                      {
                                                        value: 'false',
                                                        label: 'No',
                                                      },
                                                    ]}
                                                  />
                                                ) : conditionType ===
                                                  'picklist' ? (
                                                  <Select
                                                    name={`filters.${index}.conditions.${g}.fieldValue`}
                                                    className='min-w-[150px]'
                                                    options={props.fields
                                                      .find(
                                                        field =>
                                                          field.name ===
                                                          formikProps.values
                                                            .filters[index]
                                                            .fieldName,
                                                      )
                                                      ?.picklist_values.map(
                                                        (
                                                          picklist_value: any,
                                                        ) => ({
                                                          value:
                                                            picklist_value.value,
                                                          label:
                                                            picklist_value.label ||
                                                            picklist_value.value,
                                                        }),
                                                      )}
                                                  />
                                                ) : !!referenceTo ? (
                                                  <Select
                                                    name={`filters.${index}.conditions.${g}.fieldValue`}
                                                    async={true}
                                                    className='min-w-[150px]'
                                                    defaultOptions={
                                                      defaultOptions
                                                    }
                                                    loadOptions={(
                                                      inputValue: any,
                                                      callback: (
                                                        arg0: any,
                                                      ) => void,
                                                    ) => {
                                                      Api.salesforce.data
                                                        .queryRecords({
                                                          objectName:
                                                            referenceTo,
                                                          limit: 10,
                                                          offset: 0,
                                                          filters: !!inputValue
                                                            ? `Name LIKE '%${inputValue}%'`
                                                            : undefined,
                                                        })
                                                        .then(data => {
                                                          const options =
                                                            data.records.map(
                                                              (record: any) => {
                                                                return {
                                                                  label:
                                                                    record[
                                                                      'Name'
                                                                    ] ??
                                                                    record[
                                                                      'Id'
                                                                    ],
                                                                  value:
                                                                    record[
                                                                      'Id'
                                                                    ],
                                                                }
                                                              },
                                                            )
                                                          callback(options)
                                                          setDefaultOptions(
                                                            prevOptions => [
                                                              ...options,
                                                              ...prevOptions,
                                                            ],
                                                          )
                                                        })
                                                    }}
                                                  />
                                                ) : (
                                                  ''
                                                )}
                                              </div>

                                              {g === 0 ? (
                                                <></>
                                              ) : (
                                                <XCircleIcon
                                                  className='w-[19px] h-[19px] cursor-pointer'
                                                  style={{
                                                    marginTop: '6px',
                                                    marginLeft: '7px',
                                                  }}
                                                  color='#8D8D8D'
                                                  {...{
                                                    onClick: () =>
                                                      conditionArrHelper.remove(
                                                        g,
                                                      ),
                                                  }}
                                                />
                                              )}
                                            </FilterRow>
                                            {g ===
                                              filter.conditions?.length - 1 &&
                                            formikProps.values.filters[0]
                                              .fieldName ? (
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  marginTop: '20px',
                                                  justifyContent:
                                                    'space-between',
                                                  marginBottom: '-3px',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginLeft: '-9px',
                                                  }}
                                                >
                                                  <TertiaryButton
                                                    {...{
                                                      onClick: () =>
                                                        conditionArrHelper.push(
                                                          {},
                                                        ),
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: '2px',
                                                      }}
                                                    >
                                                      <Plus
                                                        height={13}
                                                        width={13}
                                                        strokeWidth='2.25'
                                                        style={{
                                                          marginRight: '5px',
                                                        }}
                                                      />
                                                      <span
                                                        style={{
                                                          marginTop: '1px',
                                                        }}
                                                      >
                                                        Add Filter
                                                      </span>
                                                    </div>
                                                  </TertiaryButton>
                                                </div>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <SecondaryButton
                                                    {...{
                                                      onClick: () => {
                                                        props.onSubmit()
                                                        arrHelper.remove(index)
                                                      },
                                                    }}
                                                  >
                                                    Cancel
                                                  </SecondaryButton>
                                                  <AddFilter
                                                    style={{
                                                      marginLeft:
                                                        filter.conditions
                                                          ?.length === 1
                                                          ? '21px'
                                                          : '46px',
                                                      position: 'relative',
                                                      right:
                                                        filter.conditions
                                                          ?.length === 1
                                                          ? '1px'
                                                          : '26px',
                                                    }}
                                                    {...{
                                                      onClick: () => {
                                                        if (
                                                          formikProps.values
                                                            .filters[index]
                                                            .fieldName ===
                                                            'Owned by me' ||
                                                          formikProps.values
                                                            .filters[index]
                                                            .fieldName ===
                                                            'Open opportunities' ||
                                                          formikProps.values.filters[
                                                            index
                                                          ].fieldName?.includes(
                                                            'teamOwned',
                                                          ) ||
                                                          !formikProps.errors
                                                            .filters
                                                        ) {
                                                          const newFilters: CustomFilter[] =
                                                            addFilter(
                                                              formikProps.values
                                                                .filters,
                                                              props.fields,
                                                            )

                                                          const updatedFilters =
                                                            !!props
                                                              .selectedFilter
                                                              ?.key
                                                              ? [
                                                                  ...props.filters.map(
                                                                    (
                                                                      f: any,
                                                                      index: number,
                                                                    ) => {
                                                                      if (
                                                                        index ===
                                                                        props.selectedFilterIndex
                                                                      ) {
                                                                        return newFilters[0]
                                                                      } else {
                                                                        return f
                                                                      }
                                                                    },
                                                                  ),
                                                                ]
                                                              : [
                                                                  ...props.filters,
                                                                  ...newFilters,
                                                                ]
                                                          props.setFilters(
                                                            updatedFilters,
                                                          )

                                                          props.onSubmit()
                                                          arrHelper.push({})
                                                        }
                                                      },
                                                    }}
                                                    disabled={
                                                      formikProps.values
                                                        .filters[index]
                                                        .fieldName !==
                                                        'Owned by me' &&
                                                      formikProps.values
                                                        .filters[index]
                                                        .fieldName !==
                                                        'Open opportunities' &&
                                                      !formikProps.values.filters[
                                                        index
                                                      ].fieldName?.includes(
                                                        'teamOwned',
                                                      ) &&
                                                      formikProps.errors.filters
                                                    }
                                                  >
                                                    {!!props.selectedFilter?.key
                                                      ? 'Update'
                                                      : 'Apply'}
                                                  </AddFilter>
                                                </div>
                                              </div>
                                            ) : null}
                                          </>
                                        )
                                      })}
                                    </div>
                                  )
                                }}
                              />
                            </Row>
                          </Row>
                        )
                      },
                    )}
                  </div>
                )}
              />
            </div>
          )
        }}
      </Form>
    </TableFilterBlockContainer>
  )
}

export default TableFilterBlock
